import React, { useState } from 'react';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FiChevronRight, FiChevronLeft, FiDownload } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';

import base from '../assets/images/blank-square.png';

export default function Gallery(props) {
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(0);
    const { type, images } = props;
    const groupedImages = chunkArray(images, 3);
    const total = images.length;
    
    function openImage(i){
        setOpen(true);
        setCurrent(i);
    }

    function chunkArray(array, chunkSize) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    }

    const downloadImage = (imageUrl) => {
        const proxyUrl = 'http://app.bruk.test/proxy-image?image=' + imageUrl;
        const a = document.createElement('a');
        a.href = proxyUrl;

        const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <>
            <div>
                {type==="full" ?
                <>
                    <div className="row gy-4">
                        {images.map((item, index) => (
                        <div key={index} className="col-md-4 image-content" data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                            <a onClick={() => downloadImage(item.src)} className="btn-download">
                                <FiDownload />
                                <span>Baixar</span>
                            </a>
                            <img src={base} className="image-cover" onClick={() => openImage(index)} style={{backgroundImage: `url(${item.src})`}} />
                        </div>))}
                    </div>
                </>:
                <>
                    {(total === 0 || total === 1 || total === 2 || total === 3) ?
                    <div className="row gy-4">
                        {images.map((item, index) => (
                        <div key={index} className={`${total===1 ? 'col-md-12' : total===2 ? 'col-md-6' : 'col-md-4' } image-content`} onClick={() => openImage(index)} data-aos="fade-up" data-aos-delay={`${(index + 1) * 50}`}>
                            <img src={base} className="image-cover" style={{backgroundImage: `url(${item.src})`}} />
                        </div>))}
                    </div>:
                    <div className="row gy-4">
                        <Carousel
                            className="carousel-images"
                            interval={4000}
                            transitionTime={300}
                            autoPlay={false}
                            showArrows={true}
                            showIndicators={false}
                            showStatus={false}
                            showThumbs={false}
                            stopOnHover={false}
                            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                hasPrev && (
                                    <a onClick={onClickHandler} title={label} className="btn-prev">
                                        <FiChevronLeft />
                                    </a>
                                )
                            }
                            renderArrowNext={(onClickHandler, hasNext, label) =>
                                hasNext && (
                                    <a onClick={onClickHandler} title={label} className="btn-next">
                                        <FiChevronRight />
                                    </a>
                                )
                            }
                        >   
                            {groupedImages.map((group, indexGroup) => (
                                <div className="col-md-12" key={indexGroup}>
                                    <div className="row">
                                    {group.map((item, index) => (
                                        <div className="col-md-4 image-content-hover" onClick={() => openImage(indexGroup * 3 + index)} data-aos="fade-up" data-aos-delay={`${(indexGroup * 3 + index + 1) * 50}`}>
                                            <img src={base} className="image-cover" style={{backgroundImage: `url(${item.src})`}} />
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            ))}
                        </Carousel>
                    </div>}
                </>}
            </div>
            <Lightbox
                index={current}
                slides={images}
                open={open}
                close={() => setOpen(false)}
                controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
            />
        </>
    );
}